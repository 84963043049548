(function ($, generic, site) {
  Drupal.behaviors.LoyaltySignupPanelV1 = {
    validaPhoneNumber: function (data) {
      // regex: 10 digits only
      var reg = /\d{10}$/;

      if (!data || reg.test(data) === false) {
        return false;
      }

      return true;
    },
    validateEmail: function (data) {
      var reg = /^([A-Za-z0-9_\-.!#$^&*+=.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

      if (!data) {
        return false;
      }
      if (data && reg.test(data) === false) {
        return false;
      }

      return true;
    },
    restoreTemplateState: function ($container) {
      var $panels = $container.children();
      var $defaultPanel = $container.find('.js-loyalty-signup-panel-signup');

      $panels.addClass('hidden');
      $defaultPanel.removeClass('hidden');
    },
    triggerTemplates: function (userinfo, gdpr_data, $container) {
      var $form;
      var formAction;
      var gdpr_sms;
      var settingsLoyalty =
        typeof Drupal.settings.loyalty === 'object' ? Drupal.settings.loyalty : {};
      var $defaultPanel = $container.find('.js-loyalty-signup-panel-signup');
      // Default template to return
      var $panel = $container.find('.js-loyalty-signup-panel-error');
      var isRegistered = userinfo.registered_user;
      var offerUsed =
        _.isNull(userinfo.offers) || _.isUndefined(userinfo.offers)
          ? 0
          : userinfo.offers.elist15.used;

      if (isRegistered && !offerUsed) {
        // valid unused
        $panel = $container.find('.js-loyalty-signup-panel-valid-unused-v1');
      } else if (isRegistered && offerUsed) {
        // valid_used
        $panel = $container.find('.js-loyalty-signup-panel-valid-used-v1');
      } else if (!isRegistered && !offerUsed) {
        // invalid_unused
        $panel = $container.find('.js-loyalty-signup-panel-invalid-unused-v1');
      } else if (!isRegistered && offerUsed) {
        // invalid_used
        $panel = $container.find('.js-loyalty-signup-panel-invalid-used-v1');
      }

      $form = $panel.find('form');
      formAction = $form.attr('action');
      gdpr_sms = $form.data('gdpr-with-sms');

      // Hide any current content, and show the error/thanks/already_signed_up
      $defaultPanel.addClass('hidden');
      $panel.removeClass('hidden');

      site.account.initPasswordValidationTooltip();

      // passing additional GDPR parameters to registration form in the next step
      if (gdpr_sms) {
        if (gdpr_data.SMS_PROMOTIONS) {
          $form.append('<input type="hidden" name="SMS_PROMOTIONS" value="1"/>');
        }
        if (gdpr_data.MOBILE_NUMBER) {
          $form.append(
            '<input type="hidden" name="MOBILE_NUMBER" value="' + gdpr_data.MOBILE_NUMBER + '"/>'
          );
        }
      }

      // fields for TR legal requirements
      if (settingsLoyalty.force_sms_email_agrees) {
        if (gdpr_data.DATA_PRIVACY_CONSENT) {
          $form.append('<input type="hidden" name="DATA_PRIVACY_CONSENT" value="1"/>');
        }
        if (gdpr_data.COMMERCIAL_PERMISSION) {
          $form.append('<input type="hidden" name="COMMERCIAL_PERMISSION" value="1"/>');
        }
        if (gdpr_data.MOBILE_NUMBER) {
          $form.append(
            '<input type="hidden" name="MOBILE_NUMBER" value="' + gdpr_data.MOBILE_NUMBER + '"/>'
          );
        }
      }

      if ($form.length && formAction && formAction.indexOf('http') < 0) {
        $form.attr('action', 'https://' + window.location.hostname + formAction);
      }
    },
    attach: function (context, settings) {
      var self = this;
      var params = {};
      var $templates = $('.js-loyalty-signup-panel-v1', context);
      var settingsLoyalty = typeof settings.loyalty === 'object' ? settings.loyalty : {};
      var settingsLoyaltyOnlineUser = settingsLoyalty.online_user || {};
      var loyaltyEmailSettings = Drupal.settings.enable_loyalty_email_optional;
      var loyaltyEmailText = 'LOYALTY_EMAIL_PROMOTIONS';

      if ($templates.length < 1) {
        return null;
      }

      var _validateFields = function (form) {
        var output = true;
        var dataPrivacyChecked = $('.js-input-data-privacy-consent', form).is(':checked');
        var commercialPermissionChecked = $('.js-input-commercial-permission', form).is(':checked');
        var loyaltyTermsChecked = $('.js-accepted-loyalty-terms', form).is(':checked');
        var emailAddressInputValue = $('.js-loyalty-signup-panel__email-input', form).val();
        var phoneNumberInputValue = $('.js-loyalty-signup-panel__phone-input', form).val();
        var $loyaltyErrorMsgs = $('.js-loyalty-errors', form);

        $loyaltyErrorMsgs.find('li').addClass('hidden');

        if (!self.validateEmail(emailAddressInputValue)) {
          $('.loyalty-error-message__email-address', $loyaltyErrorMsgs).removeClass('hidden');
          output = false;
        }

        if (!loyaltyTermsChecked) {
          $('.loyalty-error-message__loyalty-join-consent', $loyaltyErrorMsgs).removeClass(
            'hidden'
          );
          output = false;
        } else if (
          settingsLoyalty.force_sms_email_agrees &&
          (phoneNumberInputValue === '' || !self.validaPhoneNumber(phoneNumberInputValue))
        ) {
          // to join loyalty mobile number must be provided
          $('.loyalty-error-message__phone-number', $loyaltyErrorMsgs).removeClass('hidden');
          output = false;
        }

        if (!commercialPermissionChecked) {
          $('.loyalty-error-message__commercial-consent', $loyaltyErrorMsgs).removeClass('hidden');
          output = false;
        } else if (phoneNumberInputValue !== '' && !self.validaPhoneNumber(phoneNumberInputValue)) {
          $('.loyalty-error-message__phone-number', $loyaltyErrorMsgs).removeClass('hidden');
          output = false;
        }

        if (!dataPrivacyChecked) {
          $('.loyalty-error-message__data-privacy-consent', $loyaltyErrorMsgs).removeClass(
            'hidden'
          );
          output = false;
        }

        if (settingsLoyaltyOnlineUser.email_promotions_equals_commercial_permission) {
          params['PC_EMAIL_PROMOTIONS'] = 1;
        }

        return output;
      };

      $templates.each(function () {
        var $template = $(this);
        var $emailContainerNode = $('.js-loyalty-signup-panel-signup', $template);
        var $form = $('.js-loyalty-signup-panel__form', $emailContainerNode);
        // Input Elements
        var $emailInput = $('.js-loyalty-signup-panel__email-input', $emailContainerNode);
        var $commercialPermissionCheckbox;
        var $phoneNumberInput;
        // SMS Signup with DGPR agreement - auto-hiding phone number input
        var $smsCommunicationCheckbox = $('input[name=SMS_PROMOTIONS]', $emailContainerNode);
        var $smsCommunicationInput = $('input[name=MOBILE_NUMBER]', $emailContainerNode);
        // GDRP Elements
        var $gdprTextLink = $('.js-email-signup-panel__information', $emailContainerNode);
        var $closeIcon = $('.js-email-signup-panel__close-btn', $emailContainerNode);
        var $gdprOverlay = $('.js-email-signup-panel__gdpr-overlay', $emailContainerNode);

        $smsCommunicationCheckbox.bind('change', function () {
          if ($smsCommunicationCheckbox.prop('checked')) {
            $smsCommunicationInput.removeClass('hidden');
          } else {
            $smsCommunicationInput.addClass('hidden');
          }
        });

        if (settingsLoyalty.loyalty_enabled) {
          $commercialPermissionCheckbox = $('.js-input-commercial-permission', $emailContainerNode);
          $phoneNumberInput = $('.js-loyalty-signup-panel__phone-input', $emailContainerNode);

          $commercialPermissionCheckbox.on('change', function () {
            if ($(this).is(':checked')) {
              $phoneNumberInput.removeClass('hidden').prop('required', true);
            } else {
              $phoneNumberInput.addClass('hidden').prop('required', false);
            }
          });
        }

        $gdprTextLink.once().on('click.gdrpLink', function (event) {
          var expanded = $gdprOverlay.hasClass('expanded');

          event.preventDefault();
          $gdprOverlay.toggleClass('expanded', !expanded);
          if (!expanded) {
            $gdprOverlay.trigger('focus');
          }
        });

        $closeIcon.once().on('click.gdprClose', function (event) {
          event.preventDefault();
          $gdprOverlay.toggleClass('expanded', false);
          $gdprTextLink.focus();
        });

        $(document).on('gnavSignupClosed', function () {
          self.restoreTemplateState($template);
        });

        $form.once().on('submit', function (submitEvt) {
          submitEvt.preventDefault();
          var valid = true;
          var form = this;
          var $form = $(this);
          var gdpr_sms = $form.data('gdpr-with-sms');
          var $defaultPanel = $template.find('.js-loyalty-signup-panel-signup');
          // Default template to return
          var $errorPanel = $template.find('.js-loyalty-signup-panel-error');
          var $loyaltySignupAcceptedTerms = $('.js-loyalty-signup-accepted-terms', form);
          var pcEmailPromoAcceptedTerms = $loyaltySignupAcceptedTerms.hasClass(
            'js-loyalty-signup-accepted-terms'
          );
          var $loyaltyErrorMsgs = $('.js-loyalty-errors', form);
          var $pcEmailPromoAcceptedTermsErrorMsgs = $(
            '.js-loyalty-errors__accept-terms',
            $loyaltyErrorMsgs
          );

          if (
            $loyaltySignupAcceptedTerms &&
            pcEmailPromoAcceptedTerms &&
            !$loyaltySignupAcceptedTerms.is(':checked')
          ) {
            $pcEmailPromoAcceptedTermsErrorMsgs.removeClass('hidden');
            valid = false;
          } else if ($loyaltySignupAcceptedTerms && pcEmailPromoAcceptedTerms) {
            $pcEmailPromoAcceptedTermsErrorMsgs.addClass('hidden');
          }

          params = {};
          $emailInput.removeClass('error');

          if (settingsLoyalty.loyalty_enabled) {
            valid = _validateFields(form);
          }

          if (!valid) {
            return;
          }
          // Transform string into array of form elements
          $.each($form.serializeArray(), function (index, kv) {
            var $field = $('input[name="' + kv.name + '"]', $form);
          
            // for the unchecked checkboxes we want to send empty strings to backend for processing
            var isUncheckedBox = $field.is(':checkbox') && !$field.prop('checked');

            if (kv.name === loyaltyEmailText && isUncheckedBox === false && loyaltyEmailSettings) {
              params[$field.attr('data-loyalty')] = '1';
            }

            params[kv.name] = isUncheckedBox ? '' : kv.value;
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var userinfo = response.userinfo || {};
              var gdpr_data = gdpr_sms
                ? {
                    SMS_PROMOTIONS: params['SMS_PROMOTIONS'],
                    MOBILE_NUMBER: params['MOBILE_NUMBER']
                  }
                : {};

              gdpr_data = settingsLoyalty.force_sms_email_agrees
                ? {
                    DATA_PRIVACY_CONSENT: params['DATA_PRIVACY_CONSENT'],
                    COMMERCIAL_PERMISSION: params['COMMERCIAL_PERMISSION'],
                    MOBILE_NUMBER: params['MOBILE_NUMBER']
                  }
                : {};
              self.triggerTemplates(userinfo, gdpr_data, $template);
            },
            onFailure: function (jsonRpcResponse) {
              var messages = jsonRpcResponse.getMessages();
              var tmp = [];
              var output = '';
              var proceed = 1;
              var errorMessage = $errorPanel.text().trim();
              var $inputParent = $emailInput.parent();

              // Get the error messages from the rpc response, and use them in the error template
              $.each(messages, function (i, message) {
                tmp.push(message.text);
                if (message.key.match('(?:required|invalid)\.(?:pc_email_address|pc_email_promotions|accepted_loyalty_terms|accepted_privacy_policy)')) {
                  $emailInput.addClass('error');
                  $inputParent.next('.error').remove();
                  $inputParent.after('<div class="error">' + message.text + '</div>');
                  proceed = 0;
                }
              });

              if (proceed) {
                // Display BE message if there's not a CMS custom one.
                if (tmp.length > 0 && errorMessage.length === 0) {
                  output = '<p>' + tmp.join('<br />') + '</p>';
                  $errorPanel.html(output);
                }
                // Hide any current content, and show the error/thanks/already_signed_up
                $defaultPanel.addClass('hidden');
                $errorPanel.removeClass('hidden');
              }
            }
          });
        });
      });
    }
  };
})(
  (window.jQuery = window.jQuery || function () {}),
  (window.generic = window.generic || {}),
  (window.site = window.site || {})
);
